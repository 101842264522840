import { useQuery } from 'react-query';

import { FileType, FileTypeApiClient } from '../services/ApiService';

import { repofilesyncApiUrl } from 'config';
import axiosInstance from '../utils/axios';
const apiClient = new FileTypeApiClient(repofilesyncApiUrl, axiosInstance);
export const queryKey = 'fileType';

export const useFileTypes = () => {
  return useQuery<FileType[], Error>(queryKey, async () => apiClient.fileTypeGetAll());
};

export const useFileType = (id: number) => {
  return useQuery<FileType, Error>(queryKey, async () => apiClient.fileType(id));
};
