import { Box, Paper, PaperProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

// ----------------------------------------------------------------------

interface ZeroResultsReturnedProps extends PaperProps {
  itemRequesting?: string;
  fullMessage?: string;
  action?: ReactNode;
}

export default function ZeroResultsReturned({
  itemRequesting = '',
  fullMessage,
  action,
  ...other
}: ZeroResultsReturnedProps) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {fullMessage ? (
          <>{fullMessage}</>
        ) : (
          <span>
            There doesn't seem to be any &nbsp;
            <strong>&quot;{itemRequesting}&quot;</strong> yet.
          </span>
        )}
      </Typography>

      {action && (
        <Typography variant="body2" align="center">
          Do you want to create one?
          <Box component="span" sx={{ flexShrink: 0, marginTop: 1, display: 'block' }}>
            {action}
          </Box>
        </Typography>
      )}
    </Paper>
  );
}
