// material
import { Box, Card, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Label from 'components/Label';

import { S3File } from 'services/ApiService';
import { repofilesyncApiUrl } from 'config';

// ----------------------------------------------------------------------

const S3FileImgStyle = styled('img')(({ theme }) => ({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
}));

// ----------------------------------------------------------------------

type S3FileCardProps = {
  file: S3File;
  onOpenLightbox: (value: string) => void;
};

const getImage = (s3FileId: number | undefined) => `${repofilesyncApiUrl}/api/v1/File/${s3FileId}`;

export default function S3FileCard({ file, onOpenLightbox }: S3FileCardProps) {
  const { fileName, s3FileId } = file;

  const name = fileName;
  const status = 'valid';
  const imgSrc = getImage(s3FileId);
  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative', cursor: 'zoom-in' }}>
        {status && (
          <Label
            variant="filled"
            color={'info'}
            sx={{
              top: 16,
              right: 16,
              zIndex: 9,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {status}
          </Label>
        )}
        <S3FileImgStyle alt={name || ''} src={imgSrc} onClick={() => onOpenLightbox(imgSrc)} />
      </Box>

      <Stack spacing={2} sx={{ p: 2 }}>
        <Typography variant="subtitle2" style={{ wordWrap: 'break-word' }}>
          {name}
        </Typography>
      </Stack>
    </Card>
  );
}
