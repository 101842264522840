// material
import { Container, Grid, Typography, Card, Stack, CardContent } from '@mui/material';

// components
import Page from 'components/Page';
import useOrganization from '../../../hooks/useOrganization';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';

import { repofilesyncApiUrl } from 'config';
import { SourceControlInstallationApiClient } from '../../../services/ApiService';

import axios from 'axios';

const axiosInstance = axios.create({
  withCredentials: true
});

const apiClient = new SourceControlInstallationApiClient(repofilesyncApiUrl, axiosInstance);

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Setup() {
  const { selectedOrganization, isLoading } = useOrganization();

  let query = useQuery();

  var installation_id = query.get('installation_id');
  var setup_action = query.get('setup_action');
  // TODO IF "setup action" is 'update', move straight through to 'update' repositories
  console.log(setup_action);
  // e.g. setup?installation_id=50644980&setup_action=update

  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

  useEffect(() => {
    // Only call oncem if the redirect url is not set and other two params are set
    // if this is called multiple times it will break the flow due to the session.
    if (redirectUrl == null && !!installation_id && !!selectedOrganization) {
      apiClient
        .startSession(selectedOrganization?.organizationId, parseInt(installation_id))
        .then((response) => {
          setRedirectUrl(response ?? null);
        });
    }
  }, [redirectUrl, installation_id, selectedOrganization]);

  return (
    <Page title="Setup Integration | RepoFileSync">
      <Container maxWidth="sm">
        <Typography variant="h5" sx={{ mb: 3 }}>
          Setup Integration
        </Typography>

        {!isLoading && selectedOrganization && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Stack spacing={{ xs: 2, md: 3 }} direction="column">
                    <Stack spacing={{ xs: 2, md: 3 }} direction="row">
                      <p>The Github App ({installation_id}) will install on:</p>
                      <div>{selectedOrganization.name}</div>
                    </Stack>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={!!isLoading}
                      onClick={() => {
                        if (!redirectUrl) return; // do nothing
                        window.location.replace(redirectUrl);
                      }}
                    >
                      Proceed
                    </LoadingButton>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
