import { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Grid,
  Typography,
  CardContent,
  CardActions,
  Button,
  Box,
  MenuItem,
  CardActionArea,
  Stack
} from '@mui/material';

import { IconButtonAnimate } from 'components/animate';
import MenuPopover from 'components/MenuPopover';

// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// @types
//
import { UploadRequest } from 'services/ApiService';

import { Icon } from '@iconify/react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import editFill from '@iconify/icons-eva/edit-fill';

import { useDeleteUploadRequest } from '../../../../api/UploadRequest';
import MinimalLabelAndValue from '../../../../components/MinimalLabelAndValue';
import Label from '../../../../components/Label';
import { isEmpty, max } from 'lodash-es';

type UploadRequestMoreMenuProps = {
  id?: number | undefined;
  handleDelete: VoidFunction;
};

const MoreMenuButton = ({ id, handleDelete }: UploadRequestMoreMenuProps) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        size="large"
        color="inherit"
        sx={{ opacity: 0.48 }}
        onClick={handleOpen}
      >
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButtonAnimate>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ p: 1, mt: -1, width: 'auto' }}
      >
        <MenuItem
          component={RouterLink}
          to={`${PATH_DASHBOARD.fileSyncTask.root}/${id}/edit/`}
          sx={{ py: 0.75, px: 1.5, borderRadius: 0.75 }}
        >
          <Box
            component={Icon}
            icon={editFill}
            sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }}
          />
          <Typography variant="body2">Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDelete();
            handleClose();
          }}
          sx={{ py: 0.75, px: 1.5, borderRadius: 0.75, color: 'error.main' }}
        >
          <Box
            component={Icon}
            icon={trash2Outline}
            sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }}
          />
          <Typography variant="body2">Delete</Typography>
        </MenuItem>
      </MenuPopover>
    </>
  );
};

// ----------------------------------------------------------------------

type FileSyncTaskCardProps = {
  fileSyncTask: UploadRequest;
};

export default function FileSyncTaskCard({ fileSyncTask }: FileSyncTaskCardProps) {
  const {
    title,
    message,
    uploadRequestId: id,
    dueDate,
    createdDt,
    updatedDt,
    reference,
    recipient,
    files
  } = fileSyncTask;

  const mainLink = `${PATH_DASHBOARD.fileSyncTask.root}/${id}`;
  const editLink = `${PATH_DASHBOARD.fileSyncTask.root}/${id}/edit`;

  const mutateDeleteUploadRequest = useDeleteUploadRequest();

  const handleDelete = async (uploadRequestId: number) => {
    if (!!uploadRequestId) {
      await mutateDeleteUploadRequest.mutateAsync(uploadRequestId);
    }
  };

  const latestFileUploadDate = max(files?.map((file) => file.uploadedDt));
  return (
    <Grid item xs={12}>
      <Card>
        <CardActionArea component={RouterLink} to={mainLink}>
          <CardContent sx={{ pb: 1 }}>
            <Stack flexDirection={'row'} justifyContent="space-between">
              <Typography variant="h5" component="div">
                {title}
              </Typography>
              {isEmpty(fileSyncTask.files) ? (
                <Label color="error">No Files Uploaded</Label>
              ) : (
                <Label color="success">Files Uploaded</Label>
              )}
            </Stack>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={12}>
                    <MinimalLabelAndValue label={'Message'} value={message} />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <MinimalLabelAndValue label={'Reference'} value={reference} />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MinimalLabelAndValue label={'Created'} value={createdDt} />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MinimalLabelAndValue label={'Updated'} value={updatedDt} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={3}>
                    <Stack flexDirection={'row'} justifyContent="space-between">
                      <MinimalLabelAndValue
                        label={'Files'}
                        value={!isEmpty(fileSyncTask.files) ? `${fileSyncTask.files?.length}` : '0'}
                        valueSx={!isEmpty(fileSyncTask.files) ? { fontWeight: 'bold' } : undefined}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    {latestFileUploadDate && (
                      <MinimalLabelAndValue label={'Last Uploaded'} value={latestFileUploadDate} />
                    )}
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MinimalLabelAndValue
                      valueSx={isEmpty(fileSyncTask.files) ? { fontWeight: 'bold' } : undefined}
                      label={'Due Date'}
                      value={dueDate}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MinimalLabelAndValue label={'Recipient'} value={recipient} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Stack direction={'row'} spacing={1}>
              <Button size="small" component={RouterLink} to={editLink}>
                Edit
              </Button>
              <Typography
                component={Stack}
                direction="column"
                justifyContent="center"
                variant="subtitle1"
                sx={{
                  width: 255
                }}
              >
                Link to Repo:
              </Typography>
            </Stack>
            <MoreMenuButton
              id={id}
              handleDelete={() => {
                if (!!id) {
                  handleDelete(id);
                }
              }}
            />
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
}
