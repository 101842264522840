// material
import { styled } from '@mui/material/styles';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

//
import { UploadRequest } from 'services/ApiService';
import { S3FileList } from 'sections/S3File';
import { downloadUploadRequestsFiles } from '../../../../api/UploadRequest';

import { Icon } from '@iconify/react';
import downloadFill from '@iconify/icons-eva/download-fill';
import editFill from '@iconify/icons-eva/edit-fill';

import { isNullOrUndefined } from '../../../../utils/helpers';
import MinimalLabelAndValue from '../../../../components/MinimalLabelAndValue';
import { isEmpty } from 'lodash-es';
import mixpanel from 'mixpanel-browser';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import Label from '../../../../components/Label';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up(1368)]: {
    padding: theme.spacing(5, 8)
  }
}));

// ----------------------------------------------------------------------

type UploadRequestDetailsFilesViewProps = {
  uploadRequest: UploadRequest;
};

export default function UploadRequestDetailsFilesView({
  uploadRequest
}: UploadRequestDetailsFilesViewProps) {
  const {
    title,
    message,
    uploadRequestId,
    dueDate,
    createdDt,
    updatedDt,
    reference,
    recipient,
    files: urFiles
  } = uploadRequest;
  const files = urFiles || [];

  const handleDownloadAllFiles = async () => {
    if (!!uploadRequestId) {
      downloadUploadRequestsFiles(uploadRequestId);
    }
    mixpanel.track('Files: User Downloaded', {
      numberOfFiles: files?.length,
      uploadRequestId: uploadRequestId
    });
  };

  return (
    <RootStyle>
      <Stack spacing={1}>
        <Stack justifyContent={'space-between'} direction="row">
          <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
            {title}
          </Typography>

          <Button
            startIcon={<Icon icon={editFill} />}
            variant="outlined"
            component={RouterLink}
            to={`${PATH_DASHBOARD.receive.editUploadRequest}/${uploadRequestId}/edit/`}
            sx={{ py: 0.75, px: 1.5, borderRadius: 0.75 }}
          >
            Edit
          </Button>
        </Stack>

        <Divider flexItem />
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <MinimalLabelAndValue label={'Message'} value={message} direction={'column'} />

              <MinimalLabelAndValue label={'Reference'} value={reference} />
              <MinimalLabelAndValue label={'Due Date'} value={dueDate} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <Stack direction="row" justifyContent={'space-between'}>
                <MinimalLabelAndValue
                  label={`Number of ${
                    !isNullOrUndefined(files) && !!files && files.length === 1 ? ` File` : ' Files'
                  }`}
                  value={!isNullOrUndefined(files) ? `${files?.length}` : '0'}
                />
                {isEmpty(files) ? (
                  <Label color="error">No Files Uploaded</Label>
                ) : (
                  <Label color="success">Files Uploaded</Label>
                )}
              </Stack>
              <MinimalLabelAndValue label={'Recipient'} value={recipient} />
              <MinimalLabelAndValue label={'Created'} value={createdDt} />
              <MinimalLabelAndValue label={'Updated'} value={updatedDt} />
            </Stack>
          </Grid>
        </Grid>
        {files.length > 0 && (
          <>
            <Divider variant="middle" flexItem />
            <Button
              fullWidth
              disabled={isEmpty(files)}
              size="large"
              variant="contained"
              color="secondary"
              startIcon={<Icon icon={downloadFill} />}
              onClick={() => handleDownloadAllFiles()}
            >
              {`Download ${files.length > 1 ? `${files.length} files` : 'file'}`}
            </Button>
          </>
        )}

        <Divider variant="middle" flexItem />
        <S3FileList files={files} isLoad={false} />
      </Stack>
    </RootStyle>
  );
}
