import { repofilesyncApiUrl } from 'config';
import { useQuery } from 'react-query';
import { FileApiClient } from 'services/ApiService';
import { UploadedFile } from '../@types/upload';
import axiosInstance from '../utils/axios';

const apiClient = new FileApiClient(repofilesyncApiUrl, axiosInstance);
export const queryKey = 's3File';

export const uploadFiles = async (urlLinkCode: string, files: UploadedFile[]) => {
  return apiClient.filePut(
    urlLinkCode,
    files.map((f: UploadedFile) => ({ data: f, fileName: f.name }))
  );
};

export const useGetFile = (s3FileId: number | undefined) => {
  return useQuery<File, Error>(
    [queryKey, s3FileId],
    async () => {
      if (!!s3FileId) {
        const fileResponse = await apiClient.fileGet(s3FileId);
        return new File([fileResponse.data], fileResponse.fileName || 'file-' + s3FileId, {
          type: fileResponse.data.type
        });
      }
      throw new Error('Id must not be undefined');
    },
    {
      enabled: !!s3FileId
    }
  );
};
