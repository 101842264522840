import { useState } from 'react';
import { findIndex, groupBy, isEmpty } from 'lodash-es';
// material
import { Skeleton, Grid, Stack, Typography } from '@mui/material';
import LightboxModal from 'components/LightboxModal';
import { S3File } from 'services/ApiService';
import S3FileCard from './S3FileCard';
import { repofilesyncApiUrl } from 'config';
import { fDateTimeSuffix } from '../../utils/formatTime';
import FileDetailsLightbox from './FileDetailsLightbox';

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(12)].map((_, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '115%', borderRadius: 2 }} />
      </Grid>
    ))}
  </>
);

type S3FileListProps = {
  files: S3File[];
  isLoad: boolean;
};

const getImage = (s3FileId: number | undefined) => `${repofilesyncApiUrl}/api/v1/File/${s3FileId}`;

export default function S3FileList({ files, isLoad, ...other }: S3FileListProps) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState<number>(0);

  const images = files.map((file: S3File) => getImage(file.s3FileId));
  const groupedFiles = groupBy(files, (f) => f.uploadedDt);

  const handleOpenLightbox = (url: string) => {
    const selectedImage = findIndex(images, (index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  return (
    <Stack spacing={3} {...other}>
      {Object.entries(groupedFiles)
        .reverse()
        .map(([key, filesByDate]) => (
          <Grid item key={key}>
            <Typography variant="subtitle2" paragraph>
              Uploaded on: {fDateTimeSuffix(key)}
            </Typography>
            <Grid container spacing={3} {...other}>
              {filesByDate.map((file) => (
                <Grid key={file.s3FileId} item xs={12} sm={6} md={3}>
                  <S3FileCard file={file} onOpenLightbox={handleOpenLightbox} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}

      {isLoad && SkeletonLoad}

      {!isLoad && isEmpty(groupedFiles) && (
        <>
          <Typography variant="h4" component="div" align="center" sx={{ mt: 5 }}>
            No files have been uploaded
          </Typography>
          <Typography variant="caption" align="center">
            You can share the upload page for this request by copying the link on the right
          </Typography>
        </>
      )}

      <LightboxModal
        imageCaption={<FileDetailsLightbox s3FileId={files[selectedImage]?.s3FileId} />}
        images={images}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onClose={() => setOpenLightbox(false)}
      />
    </Stack>
  );
}
