import { useParams, useLocation } from 'react-router-dom';
// material
import { Container, Grid } from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
// api
import { useGetFileSyncTask } from 'api/FileSyncTask';

// components
import Page from 'components/Page';
import FileSyncTaskNewForm from 'sections/_dashboard/file-sync-task/FileSyncTaskNewForm';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { sentenceCase } from 'change-case';
import useOrganization from 'hooks/useOrganization';
// ----------------------------------------------------------------------

export default function FileSyncTaskCreate() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();

  const { selectedOrganizationId } = useOrganization();

  const isEdit = pathname.includes('edit');

  const { id } = useParams();
  const fileSyncTaskId = !!id ? Number.parseInt(id) : undefined;
  const {
    data: currentFileSyncTask,
    isLoading,
    isFetched
  } = useGetFileSyncTask(fileSyncTaskId, selectedOrganizationId);

  return (
    <Page title="File Sync: Create a File Sync Task | RepoFileSync">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'New File Sync Task' : 'Edit File Sync Tasks'}
          links={[
            { name: 'Home', href: PATH_DASHBOARD.root },
            {
              name: 'File Sync Tasks',
              href: PATH_DASHBOARD.fileSyncTask.root
            },
            { name: sentenceCase(currentFileSyncTask?.title || '') }
          ]}
        />
        {isEdit && isLoading && <Grid>loading...</Grid>}
        {(!isEdit || (isEdit && isFetched)) && (
          <FileSyncTaskNewForm isEdit={isEdit} currentFileSyncTask={currentFileSyncTask} />
        )}
      </Container>
    </Page>
  );
}
