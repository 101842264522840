// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { MenuItem, Select, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

interface IProps {
  name: string; // property name
  label: string;
  options: { label: string; value: string }[];
}

export default function RHFSelect({ name, label, options, sx, ...other }: IProps & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue="" // Default value
      rules={{ required: 'This field is required' }} // Validation rule
      render={({ field }) => (
        <Select {...field} sx={sx} label={label} fullWidth>
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
}
