import { CircularProgress, Typography, Grid, Stack } from '@mui/material';

// components
import { isString } from 'lodash-es';
import { fData } from '../../utils/formatNumber';
import { getImageFromFile, ImageDimensions } from 'utils/fileUtils';
import { useEffect, useState } from 'react';
import { fDateTime } from '../../utils/formatTime';
import { useGetFile } from '../../api/File';
import MinimalLabelAndValue from '../../components/MinimalLabelAndValue';

// ----------------------------------------------------------------------
type FileDetailsLightboxProps = {
  s3FileId: number | undefined;
};

const FileDetailsLightbox = ({ s3FileId }: FileDetailsLightboxProps) => {
  const { data: file, isLoading, error } = useGetFile(s3FileId);

  const [imageSize, setImageSize] = useState<ImageDimensions | null>(null);

  useEffect(() => {
    (async () => {
      if (!!file) {
        const image = await getImageFromFile(file);
        setImageSize(image);
      }
    })();
  }, [file]);

  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <Typography variant="h6">{error.message}</Typography>;
  }

  if (!file) {
    // Return Nothing
    return <></>;
  }

  const { name, size, type, lastModified } = file;
  return (
    <Grid container>
      <Grid item xs={6}>
        <Stack spacing={1}>
          <MinimalLabelAndValue label={'Name'} value={name}></MinimalLabelAndValue>
          <MinimalLabelAndValue label={'Type'} value={type}></MinimalLabelAndValue>
          <MinimalLabelAndValue
            label={'Last Modified'}
            value={fDateTime(lastModified)}
          ></MinimalLabelAndValue>
          <MinimalLabelAndValue
            label={'Size'}
            value={isString(file) ? '' : fData(size || 0)}
          ></MinimalLabelAndValue>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={1}>
          <MinimalLabelAndValue
            label={'Image Dimensions'}
            value={`${imageSize?.width}x${imageSize?.height}`}
          ></MinimalLabelAndValue>
          <MinimalLabelAndValue
            label={'Image Width'}
            value={imageSize?.width}
          ></MinimalLabelAndValue>
          <MinimalLabelAndValue
            label={'Image Height'}
            value={imageSize?.height}
          ></MinimalLabelAndValue>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FileDetailsLightbox;
