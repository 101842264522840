import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import { fDateTime } from '../utils/formatTime';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/material';

interface MinimalLabelAndValueProps {
  label: string;
  value: string | number | Date | null | undefined;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | undefined;
  labelSx?: SxProps<Theme>;
  valueSx?: SxProps<Theme>;
}

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary
}));

const parseDate = (dateValue: Date | null | undefined) => (!!dateValue ? fDateTime(dateValue) : '');

const MinimalLabelAndValue = ({
  label,
  value,
  direction,
  labelSx,
  valueSx
}: MinimalLabelAndValueProps) => {
  const valToDisplay = value instanceof Date ? parseDate(value) : value;
  return (
    <Stack direction={direction || 'row'}>
      <LabelStyle sx={{ pr: 1, ...labelSx }}>{`${label}:`}</LabelStyle>
      <Typography
        component="p"
        title={`${valToDisplay}`}
        sx={{ overflow: 'clip', textOverflow: 'ellipsis', ...valueSx }}
      >
        {valToDisplay}
      </Typography>
    </Stack>
  );
};
export default MinimalLabelAndValue;
