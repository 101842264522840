import { useMemo, useEffect } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// material
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  CardHeader,
  CardContent,
  Divider
} from '@mui/material';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { FileSyncTask, RepositoryType, FileSyncRepository } from '../../../services/ApiService';
import { useCreateFileSyncTask, useUpdateFileSyncTask } from 'api/FileSyncTask';

import { fDateTimeSuffix } from '../../../utils/formatTime';
import mixpanel from 'mixpanel-browser';
import { some } from 'lodash-es';
import useOrganization from '../../../hooks/useOrganization';
import useResponsive from '../../../hooks/useResponsive';

import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import useRepository from '../../../hooks/useRepository';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

interface FormValuesProps extends Partial<FileSyncTask> {}

type FileSyncTaskNewFormProps = {
  isEdit: boolean;
  currentFileSyncTask?: FileSyncTask;
};

const FileSyncTaskSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  sourceRepositoryId: Yup.number().required('Source Repository is required'),
  sourceRepository: Yup.object()
    .shape({
      id: Yup.number().required(),
      name: Yup.string().required()
    })
    .required(),
  fileSyncItems: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string().required(),
        path: Yup.string().required()
      })
    )
    .required(),
  destinationRepositories: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string().required()
      })
    )
    .required()
});

export default function FileSyncTaskNewForm({
  isEdit,
  currentFileSyncTask
}: FileSyncTaskNewFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedOrganizationId } = useOrganization();

  const mutateCreateFileSyncTask = useCreateFileSyncTask();
  const mutateUpdateFileSyncTask = useUpdateFileSyncTask();

  const navigateToFileSynkTaskDetails = (fileSyncTaskId: number | undefined) => {
    navigate(PATH_DASHBOARD.uploadRequest.root + '/' + fileSyncTaskId);
  };

  const defaultValues = useMemo(
    () => ({
      fileSyncTaskId: currentFileSyncTask?.fileSyncTaskId,
      // Gets overwritteon on submit anyway.
      organizationId: currentFileSyncTask?.organizationId || 0,
      title: currentFileSyncTask?.title,
      sourceRepositoryId: currentFileSyncTask?.sourceRepositoryId,
      sourceRepository: currentFileSyncTask?.sourceRepository,
      fileSyncItems: currentFileSyncTask?.fileSyncItems || [{ source: '', destination: '' }],
      destinationRepositories: currentFileSyncTask?.destinationRepositories || []
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFileSyncTask]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FileSyncTaskSchema),
    defaultValues
  });

  const {
    reset,
    watch,
    getValues,
    handleSubmit,
    control,
    formState: { isSubmitting, errors }
  } = methods;

  const {
    fields: fieldsFileSyncItems,
    append: appendFileSyncItems,
    remove: removeFileSyncItems
  } = useFieldArray<FormValuesProps>({
    control,
    name: 'fileSyncItems'
  });

  const { fields, append, remove } = useFieldArray<FormValuesProps>({
    control,
    name: 'destinationRepositories'
  });

  const values = watch();

  useEffect(() => {
    if (isEdit && currentFileSyncTask) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentFileSyncTask]);

  var { repositories } = useRepository();

  const selectedSourceRepository = watch('sourceRepository'); // Watch the value of 'category'
  console.log(repositories);
  console.log(selectedSourceRepository);
  var filteredDestinationRepositories = repositories.filter((r) =>
    !!selectedSourceRepository ? r.name !== selectedSourceRepository.name : r
  );

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (!selectedOrganizationId) {
        throw new Error('You must have a organization selected');
      }
      // returns upload request with the upload requirements updated with templates, if there was a template selected.
      const fileSyncTask = { ...values, organizationId: selectedOrganizationId };
      if (isEdit && !!currentFileSyncTask && currentFileSyncTask?.fileSyncTaskId) {
        await mutateUpdateFileSyncTask.mutateAsync(fileSyncTask);
        enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', { variant: 'success' });
        navigateToFileSynkTaskDetails(currentFileSyncTask?.fileSyncTaskId);
      } else {
        const { fileSyncTaskId } = await mutateCreateFileSyncTask.mutateAsync(fileSyncTask);
        mixpanel.track('Created File Sync Task');
        navigateToFileSynkTaskDetails(fileSyncTaskId);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const upMd = useResponsive('up', 'md');

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} sx={{ mb: 5 }}>
        <Grid item xs={12}>
          <Card>
            <Stack
              spacing={{ xs: 2, md: 5 }}
              direction={{ xs: 'column', md: 'row' }}
              divider={
                <Divider
                  flexItem
                  orientation={upMd ? 'vertical' : 'horizontal'}
                  sx={{ borderStyle: 'dashed' }}
                />
              }
              sx={{ p: 3 }}
            >
              <Stack sx={{ width: 1 }} spacing={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="h6" sx={{ color: 'text.disabled' }}>
                    Details:
                  </Typography>
                </Stack>
                <RHFTextField name="title" label="Title" margin="dense" />
              </Stack>

              <Stack sx={{ width: 1 }} spacing={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="h6" sx={{ color: 'text.disabled' }}>
                    Source Repository:
                  </Typography>
                </Stack>
                {/* TODO Fix drop down select, essentially the option.value, is a string txt that is saved to the control
                  when it needs to be the actual object saved to the control
                */}
                <RHFSelect
                  name={`sourceRepository`}
                  value={`sourceRepository.name`}
                  label="Source Repository"
                  size="small"
                  fullWidth
                  error={!!errors.sourceRepository?.name}
                  helperText={
                    !!errors?.sourceRepository?.name ? errors.sourceRepository.name.message : ''
                  }
                  options={repositories.map((r) => ({ label: r.name || '', value: r.name || '' }))}
                />
              </Stack>

              <Stack sx={{ width: 1 }} spacing={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="h6" sx={{ color: 'text.disabled' }}>
                    Destination Repositories:
                  </Typography>
                </Stack>

                {fields.map((item, index) => (
                  <div key={item.id}>
                    <RHFSelect
                      name={`destinationRepositories.${index}.name`}
                      label="Destination Repository"
                      size="small"
                      fullWidth
                      error={!!errors.destinationRepositories?.[index]?.name}
                      helperText={
                        errors.destinationRepositories?.[index]?.name
                          ? errors.destinationRepositories[index].name.message
                          : ''
                      }
                      options={filteredDestinationRepositories.map((r) => ({
                        label: r.name || '',
                        value: r.name || ''
                      }))}
                    />
                    <Button
                      type="button"
                      onClick={() => remove(index)}
                      disabled={fields.length === 1}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
                <Button
                  type="button"
                  onClick={() =>
                    append({
                      organizationId: selectedOrganizationId || 0, // todo: disable nullable
                      name: '',
                      owner: '',
                      repositoryType: RepositoryType.GitHub
                    })
                  }
                >
                  Add Destination Repository
                </Button>
              </Stack>
            </Stack>
            {isEdit && (
              <Stack
                spacing={2}
                direction={{ xs: 'column', sm: 'row' }}
                sx={{ p: 3, bgcolor: 'background.neutral' }}
              >
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Created
                  </Typography>
                  <Typography variant="subtitle2">
                    {!!currentFileSyncTask?.createdDt &&
                      fDateTimeSuffix(currentFileSyncTask.createdDt)}
                  </Typography>
                </Stack>

                <Stack direction="row" spacing={1}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Last Updated
                  </Typography>
                  <Typography variant="subtitle2">
                    {!!currentFileSyncTask?.updatedDt &&
                      fDateTimeSuffix(currentFileSyncTask.updatedDt)}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Files or Folders" />
            <CardContent>
              <Stack sx={{ width: 1 }} spacing={1}>
                {fieldsFileSyncItems.map((item, index) => (
                  <div key={item.id}>
                    <Controller
                      name={`fileSyncItems.${index}.source`}
                      control={control}
                      rules={{ required: 'File or Folder is required' }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={`File or Folder ${index + 1}`}
                          margin="dense"
                          fullWidth
                          error={!!errors.fileSyncItems?.[index]?.source}
                          helperText={
                            errors.fileSyncItems?.[index]?.source
                              ? errors.fileSyncItems[index].source.message
                              : ''
                          }
                        />
                      )}
                    />
                    <Button
                      type="button"
                      onClick={() => removeFileSyncItems(index)}
                      disabled={fieldsFileSyncItems.length === 1}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
                <Button
                  type="button"
                  onClick={() =>
                    appendFileSyncItems({
                      organizationId: selectedOrganizationId || 0, // todo: disable nullable
                      source: '',
                      destination: ''
                    })
                  }
                >
                  Add File or Folder
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Summary" />
            <CardContent>
              <Stack spacing={3}>
                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                  {`The file sync task will sync ${getValues()
                    .fileSyncItems?.map((item) => {
                      return `"${item.source}"`;
                    })
                    .join(', ')}
                  from ${getValues()?.sourceRepository?.name} to ${getValues()
                    .destinationRepositories?.map((item: FileSyncRepository) => {
                      return `"${item.name}"`;
                    })
                    .join(', ')}`}
                </Typography>
                <LoadingButton
                  disabled={!some(getValues()?.destinationRepositories)}
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                >
                  {!isEdit ? 'Create' : 'Save Changes'}
                </LoadingButton>
                {mutateUpdateFileSyncTask.isSuccess && (
                  <Button
                    fullWidth
                    variant="text"
                    size="large"
                    onClick={() => {
                      navigateToFileSynkTaskDetails(currentFileSyncTask?.fileSyncTaskId);
                    }}
                  >
                    View Recently Saved Changes
                  </Button>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
