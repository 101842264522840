import CopyToClipboard from '../../../components/CopyClipboard';

type UploadUrlLinkProps = {
  uploadCode?: string | null;
  size?: string;
};

export const GetUploadLinkUrlFromUrlCode = (uploadCode: string | null | undefined) => {
  const baseUrl = `https://${window.location.host}/upload/`;
  return `${baseUrl}${uploadCode}`;
};

const UploadUrlLink = ({ uploadCode, ...other }: UploadUrlLinkProps) => {
  const textToDisplay = !!uploadCode
    ? GetUploadLinkUrlFromUrlCode(uploadCode)
    : 'Code has not been created yet';
  return <CopyToClipboard value={textToDisplay} {...other} />;
};

export default UploadUrlLink;
