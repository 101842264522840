import { useQuery, useMutation, useQueryClient } from 'react-query';
import { FileSyncTask, FileSyncTaskApiClient } from '../services/ApiService';

import { repofilesyncApiUrl } from 'config';
import axiosInstance from '../utils/axios';

const apiClient = new FileSyncTaskApiClient(repofilesyncApiUrl, axiosInstance);

export const queryKey = 'fileSyncTask';
export const queryKeyAll = 'fileSyncTasks';

export const useGetAllFileSyncTasks = (
  organizationId?: number,
  enabled: boolean | undefined = true
) => {
  return useQuery<FileSyncTask[], Error>(
    [queryKeyAll, organizationId],
    () => apiClient.fileSyncTaskGetAll(organizationId!),
    {
      enabled: enabled && !!organizationId
    }
  );
};

export const useGetFileSyncTask = (id: number | undefined, organizationId: number | undefined) => {
  return useQuery<FileSyncTask, Error>(
    [queryKey, id, organizationId],
    () => apiClient.fileSyncTaskGet(id!, organizationId!),
    {
      enabled: !!id && !!organizationId
    }
  );
};

export const useCreateFileSyncTask = () => {
  const queryClient = useQueryClient();
  return useMutation<FileSyncTask, Error, FileSyncTask>(
    (fileSyncTask: FileSyncTask) => apiClient.fileSyncTaskPost(fileSyncTask),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries(queryKeyAll);
      }
    }
  );
};

export const useUpdateFileSyncTask = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, FileSyncTask>(
    (fileSyncTask: FileSyncTask) => {
      if (!!fileSyncTask?.fileSyncTaskId) {
        return apiClient.fileSyncTaskPut(fileSyncTask.fileSyncTaskId, fileSyncTask);
      } else {
        throw new Error('Request Id must not be null');
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries(queryKeyAll);
      }
    }
  );
};

export const useDeleteFileSyncTask = (id: number, organizationId: number) => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, number>(() => apiClient.fileSyncTaskDelete(id, organizationId), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyAll);
      queryClient.invalidateQueries(queryKeyAll);
    }
  });
};
