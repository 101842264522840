// material
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Divider, Typography, Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import copyFill from '@iconify/icons-eva/copy-fill';

//
import { UploadRequest } from 'services/ApiService';
import UploadUrlLink, { GetUploadLinkUrlFromUrlCode } from '../UploadUrlLink';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import UploadRequirementCardSummary from '../../upload-requirement/upload-requirement-list/UploadRequirementCardSummary';
import { PATH_UPLOAD } from '../../../../routes/paths';
import { first } from 'lodash-es';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up(1368)]: {
    padding: theme.spacing(5, 8)
  }
}));

type UploadRequestDetailsSumaryprops = {
  uploadRequest: UploadRequest;
};

export default function UploadRequestDetailsSummary({
  uploadRequest,
  ...other
}: UploadRequestDetailsSumaryprops) {
  const { urlLinkCode, uploadRequirements } = uploadRequest;
  const uploadRequirement = first(uploadRequirements);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <RootStyle {...other}>
      <Stack spacing={2}>
        <Typography variant="subtitle1" sx={{ mt: 0.5 }}>
          Upload Requirements
        </Typography>
        {uploadRequirement ? (
          <>
            <UploadRequirementCardSummary uploadRequirement={uploadRequirement} />
          </>
        ) : (
          <Typography variant="h6" sx={{ mb: 1 }}>
            None Selected
          </Typography>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Typography variant="subtitle1">Share Upload Link</Typography>

        <UploadUrlLink uploadCode={urlLinkCode} />

        <CopyToClipboard
          text={GetUploadLinkUrlFromUrlCode(urlLinkCode)}
          onCopy={() => enqueueSnackbar('Link copied to clipboard', { variant: 'success' })}
        >
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            startIcon={<Icon icon={copyFill} />}
          >
            Copy Link
          </Button>
        </CopyToClipboard>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          component={RouterLink}
          to={PATH_UPLOAD.root + '/' + urlLinkCode}
        >
          Preview Upload Screen
        </Button>
      </Stack>
    </RootStyle>
  );
}
