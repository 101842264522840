import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  AnonymousFileRequestViewModel,
  UploadRequest,
  UploadRequestsApiClient
} from '../services/ApiService';
import { saveAs } from 'file-saver';

import { repofilesyncApiUrl } from 'config';
import axiosInstance from '../utils/axios';

const apiClient = new UploadRequestsApiClient(repofilesyncApiUrl, axiosInstance);
export const queryKey = 'uploadRequest';
export const queryKeyAll = 'uploadRequests';

// https://medium.com/nerd-for-tech/common-data-fetching-patterns-for-real-apps-with-react-query-4b83188a95c1
export const useGetAllUploadRequests = (
  organizationId?: number,
  enabled: boolean | undefined = true
) => {
  return useQuery<UploadRequest[], Error>(
    // Anything using a param passed, must use it in the query key to invalidate when it changes.
    [queryKeyAll, organizationId],
    async () => apiClient.uploadRequestsGetAll(organizationId),
    {
      enabled: enabled && !!organizationId
    }
  );
};

export const useGetUploadRequest = (id: number | undefined) => {
  return useQuery<UploadRequest, Error>(
    [queryKey, id],
    async () => {
      if (!!id) {
        return apiClient.uploadRequestsGet(id);
      }
      throw new Error('Id must not be undefined');
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false
    }
  );
};

export const useGetUrlLinkCodeUploadRequest = (urlLinkCode: string | undefined) => {
  return useQuery<AnonymousFileRequestViewModel, Error>(
    [queryKey, urlLinkCode],
    async () => {
      if (!!urlLinkCode) {
        return apiClient.uploadRequestsGetUrlLinkCode(urlLinkCode);
      }
      throw new Error('Link code undefined or false');
    },
    {
      enabled: !!urlLinkCode
    }
  );
};

export const useCreateUploadRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<UploadRequest, Error, UploadRequest>(
    (uploadRequest: UploadRequest) => apiClient.uploadRequestsPost(uploadRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      }
    }
  );
};

export const useUpdateUploadRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, UploadRequest>(
    (uploadRequest: UploadRequest) => {
      if (!!uploadRequest?.uploadRequestId) {
        return apiClient.uploadRequestsPut(uploadRequest.uploadRequestId, uploadRequest);
      } else {
        throw new Error('Request Id must not be null');
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      }
    }
  );
};

export const useDeleteUploadRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, number>((id: number) => apiClient.uploadRequestsDelete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    }
  });
};

export const downloadUploadRequestsFiles = async (id: number) => {
  try {
    const file = await apiClient.files(id);
    // https://stackoverflow.com/questions/47802518/download-big-files
    saveAs(file.data, file.fileName);
  } catch (error) {
    console.error(error);
  }
};
