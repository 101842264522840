// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components

import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

const getIcon = (icon: string) => <Icon icon={icon} width={24} height={24} />;

const ICONS = {
  createRequest: getIcon('eva:plus-circle-outline'),
  requests: getIcon('mdi:file-cabinet'),
  send: getIcon('mdi:arrow-collapse-up'),
  requirements: getIcon('mdi:clipboard-check-multiple'),
  help: getIcon('mdi:help')
};

const sidebarConfig = [
  {
    subheader: '',
    items: [
      {
        title: ' New Request',
        path: PATH_DASHBOARD.receive.root,
        icon: ICONS.createRequest,
        isButton: true
      }
    ]
  },
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Receive',
    items: [
      {
        title: 'File Sync Task',
        path: PATH_DASHBOARD.fileSyncTask.root,
        icon: ICONS.requests
      },
      {
        title: 'Requests',
        path: PATH_DASHBOARD.uploadRequest.root,
        icon: ICONS.requests
      },
      {
        title: 'Requirement Templates',
        // needs to be .root, for sidebar highlighting on sub pages
        path: PATH_DASHBOARD.uploadRequirement.root,
        icon: ICONS.requirements
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Help',
    items: [
      {
        title: 'Request a feature',
        path: PATH_DASHBOARD.management.requestAFeature,
        icon: ICONS.help
      }
    ]
  }
];

export default sidebarConfig;
