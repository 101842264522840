// material
import { Container, Typography } from '@mui/material';

// components
import Page from 'components/Page';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { repofilesyncApiUrl } from 'config';
import { SourceControlInstallationApiClient } from '../../../services/ApiService';
import axios from 'axios';

const axiosInstance = axios.create({
  withCredentials: true
});
const apiClient = new SourceControlInstallationApiClient(repofilesyncApiUrl, axiosInstance);

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function OAuthCallbackRedirect() {
  let query = useQuery();

  var code = query.get('code');
  var state = query.get('state');

  const [response, setResponse] = useState<string | null>(null);

  useEffect(() => {
    if (!!code && !!state) {
      apiClient.callback(code, state).then((res) => {
        setResponse(res ?? null);
      });
    }
  }, [code, state]);

  return (
    <Page title="Approval Completed | RepoFileSync">
      <Container maxWidth="sm">
        <Typography variant="h5" sx={{ mb: 3 }}>
          Approval Completed, Finishing Setup...
        </Typography>
        {response}
      </Container>
    </Page>
  );
}
