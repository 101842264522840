import { useQuery } from 'react-query';
import { RepositoryApiClient, SourceControlRepository } from '../services/ApiService';

import { repofilesyncApiUrl } from 'config';
import axiosInstance from '../utils/axios';

const apiClient = new RepositoryApiClient(repofilesyncApiUrl, axiosInstance);
export const queryKey = 'repository';

export const useGetRepositories = (
  organizationId: number | undefined,
  enabled: boolean | undefined = true
) => {
  return useQuery<SourceControlRepository[], Error>(
    [queryKey, organizationId],
    async () => {
      if (!!organizationId) {
        return apiClient.repository(organizationId);
      }
      throw new Error('organizationId must not be undefined');
    },
    {
      enabled: enabled && !!organizationId
    }
  );
};
