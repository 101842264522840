// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
import { DateTimePicker } from '@mui/lab';

// ----------------------------------------------------------------------

interface IProps {
  name: string;
}

export default function RHFDateTimePicker({ name, ...other }: IProps & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <DateTimePicker
          {...field}
          label="Upload Due Date"
          value={field.value}
          onChange={(newValue) => {
            // If valid date.
            field.onChange(newValue);
          }}
          renderInput={(params) => {
            return <TextField fullWidth {...params} helperText={other?.helperText} {...other} />;
          }}
        />
      )}
    />
  );
}
