import { createContext, ReactNode, useContext } from 'react';

import { SourceControlRepository } from '../services/ApiService';
import { OrganizationContext } from './OrganizationContext';
import { useGetRepositories } from '../api/Repository';

// ----------------------------------------------------------------------

type RepositoryContextProps = {
  isLoading: boolean;
  repositories: SourceControlRepository[];
};

const RepositoryContext = createContext<RepositoryContextProps | null>(null);

const RepositoryProvider = ({ children }: { children: ReactNode }) => {
  const organizationContext = useContext(OrganizationContext);

  const { data: repositories, isLoading } = useGetRepositories(
    organizationContext?.selectedOrganizationId
  );

  return (
    <RepositoryContext.Provider
      value={{
        isLoading: organizationContext?.isLoading || isLoading,
        repositories: repositories || []
      }}
    >
      {children}
    </RepositoryContext.Provider>
  );
};

export { RepositoryContext, RepositoryProvider };
