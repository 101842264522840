import { useContext } from 'react';
import { RepositoryContext } from '../contexts/RepositoryContext';

// ----------------------------------------------------------------------

const useRepository = () => {
  const context = useContext(RepositoryContext);

  if (!context) throw new Error('Repository context must be use inside RepositoryProvider');

  return context;
};

export default useRepository;
