// material
import { Typography, Stack } from '@mui/material';

//
import { UploadRequirement, UploadRequirementTemplate } from 'services/ApiService';
import Label from 'components/Label';
import { isNullOrUndefined } from 'utils/helpers';
import { useFileTypes } from 'api/FileType';
import { some } from 'lodash-es';

// ----------------------------------------------------------------------

const renderRequirement = (
  label: string,
  value: string | number | null | undefined,
  valueUnit: string | null | undefined = undefined
) => {
  if (!isNullOrUndefined(value)) {
    return (
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" component="p" sx={{ color: 'text.secondary' }}>
          {label}
        </Typography>
        <Label variant="filled">
          {value}
          {!isNullOrUndefined(valueUnit) && ` ${valueUnit}`}
        </Label>
      </Stack>
    );
  }
};

const renderListRequirement = (label: string, values: Array<any> | null | undefined) => {
  if (!isNullOrUndefined(values) && values?.length) {
    return renderRequirement(label, values?.join(', '));
  }
};

const renderBooleanRequirement = (label: string, value: boolean | null | undefined) => {
  if (!isNullOrUndefined(value)) {
    return (
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" component="p" sx={{ color: 'text.secondary' }}>
          {label}
        </Typography>
      </Stack>
    );
  }
};

type UploadRequirementCardSummaryProps = {
  uploadRequirement: UploadRequirement | UploadRequirementTemplate;
};

const UploadRequirementCardSummary = ({ uploadRequirement }: UploadRequirementCardSummaryProps) => {
  const { data } = useFileTypes();
  const fileTypes = data || [];

  const selectedAllowedFileTypeOptions = fileTypes.filter((ft) =>
    some(uploadRequirement?.allowedFileTypes, { fileTypeId: ft.fileTypeId })
  );

  const selectedBlockedFileTypeOptions = fileTypes.filter((ft) =>
    some(uploadRequirement?.blockedFileTypes, { fileTypeId: ft.fileTypeId })
  );

  return (
    <Stack spacing={1}>
      {renderRequirement(
        'Min Height',
        uploadRequirement?.minHeight,
        uploadRequirement?.minHeightUnit
      )}
      {renderRequirement('Min Width', uploadRequirement?.minWidth, uploadRequirement?.minWidthUnit)}
      {renderRequirement(
        'Max Height',
        uploadRequirement?.maxHeight,
        uploadRequirement?.maxHeightUnit
      )}
      {renderRequirement('Max Width', uploadRequirement?.maxWidth, uploadRequirement?.maxWidthUnit)}

      {uploadRequirement?.isTransparent &&
        renderBooleanRequirement(
          'Filetype must support transparency',
          uploadRequirement?.isTransparent
        )}
      {renderRequirement(
        'Min File Size',
        uploadRequirement?.minFileSize,
        uploadRequirement?.minFileSizeUnit
      )}

      {renderRequirement(
        'Max File Size',
        uploadRequirement?.maxFileSize,
        uploadRequirement?.maxFileSizeUnit
      )}

      {renderListRequirement(
        'Allowed File Types',
        selectedAllowedFileTypeOptions.map((fileType) => fileType.extension)
      )}

      {renderListRequirement(
        'Blocked File Types',
        selectedBlockedFileTypeOptions.map((fileType) => fileType.extension)
      )}
    </Stack>
  );
};

export default UploadRequirementCardSummary;
