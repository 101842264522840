import { sentenceCase } from 'change-case';
import { useParams } from 'react-router-dom';

// material
import { Card, Grid, Skeleton, Container, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { UploadRequestDetailsFilesView } from 'sections/_dashboard/upload-request/upload-request-details';
import useSettings from 'hooks/useSettings';
import { useGetUploadRequest } from 'api/UploadRequest';

const SkeletonLoad = (
  <Grid container spacing={3}>
    <Grid item xs={12} md={6} lg={7}>
      <Grid item xs={12} md={6} lg={7}>
        <Skeleton variant="text" height={50} />
        <Skeleton variant="text" height={45} />
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Skeleton variant="rectangular" width={72} height={72} sx={{ borderRadius: 2 }} />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" width={72} height={72} sx={{ borderRadius: 2 }} />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" width={72} height={72} sx={{ borderRadius: 2 }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={6} lg={5}>
      <Skeleton variant="text" height={40} />
      <Skeleton variant="text" height={40} />
      <Skeleton variant="text" height={50} />
    </Grid>
  </Grid>
);

export default function FileSyncTaskDetails() {
  const { themeStretch } = useSettings();

  const { id } = useParams();
  const uploadRequestId = !!id ? Number.parseInt(id) : undefined;
  const { data: fileSyncTask, error } = useGetUploadRequest(uploadRequestId);

  return (
    <Page title="File Sync Task: Details | RepoFileSync">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="File Sync Task Details"
          links={[
            { name: 'Home', href: PATH_DASHBOARD.root },
            {
              name: 'File Sync Task',
              href: PATH_DASHBOARD.fileSyncTask.root
            },
            { name: sentenceCase(fileSyncTask?.title || '') }
          ]}
        />

        {fileSyncTask && (
          <>
            <Card>
              <UploadRequestDetailsFilesView uploadRequest={fileSyncTask} />
            </Card>
          </>
        )}

        {error && <Typography variant="h6">{error.message}</Typography>}

        {!fileSyncTask && !error && SkeletonLoad}
      </Container>
    </Page>
  );
}
