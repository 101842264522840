import { useState } from 'react';

import { filter } from 'lodash-es';
import { Link as RouterLink } from 'react-router-dom';

import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

// material
import { Box, Button, Container, Stack, Grid, Divider, Skeleton } from '@mui/material';
//Helpers
// api
import { UploadRequest } from '../../../services/ApiService';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from 'components/Page';
import SearchNotFound from 'components/SearchNotFound';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { useGetAllFileSyncTasks } from 'api/FileSyncTask';
import ZeroResultsReturned from 'components/ZeroResultsReturned';
import useOrganization from '../../../hooks/useOrganization';
import {
  FileSyncTaskCard,
  FileSyncTaskListToolbar
} from 'sections/_dashboard/file-sync-task/file-sync-task-list';

// ----------------------------------------------------------------------

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Anonymous = Record<string | number, string>;

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array: UploadRequest[] = [],
  comparator: (a: any, b: any) => number,
  query: string
) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      array,
      (uploadRequest) =>
        (uploadRequest.title || '').toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

// ----------------------------------------------------------------------
export default function FileSyncTaskList() {
  const { selectedOrganizationId } = useOrganization();
  const { themeStretch } = useSettings();
  const order = 'asc';
  const [filterName, setFilterName] = useState('');

  const orderBy = 'createdAt';

  const { data, isLoading, isFetched } = useGetAllFileSyncTasks(selectedOrganizationId);
  const fileSyncTasks = data || [];

  const handleFilterByName = (filterName: string) => {
    setFilterName(filterName);
  };

  const filteredFileSyncTasks = applySortFilter(
    fileSyncTasks,
    getComparator(order, orderBy),
    filterName
  );

  const isFileSyncTasksNotFound = filteredFileSyncTasks.length === 0;

  const NewFileSyncTaskButton = (
    <Button
      variant="contained"
      component={RouterLink}
      to={PATH_DASHBOARD.fileSyncTask.new}
      startIcon={<Icon icon={plusFill} />}
    >
      New File Sync Task
    </Button>
  );
  return (
    <Page title="Uploads: All File Sync Tasks | RepoFileSync">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="File Sync Tasks"
          links={[
            { name: 'Home', href: PATH_DASHBOARD.root },
            {
              name: 'File Sync Tasks'
            }
          ]}
          action={NewFileSyncTaskButton}
        />
        <Stack divider={<Divider flexItem />} spacing={1}>
          <FileSyncTaskListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          {isLoading && (
            // Skeleton Load
            <Grid container rowSpacing={3}>
              {[...Array(4)].map((_, index) => (
                <Grid item xs={12} md={12} key={index}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    sx={{ height: 200, borderRadius: 2 }}
                  />
                  <Box sx={{ display: 'flex', mt: 1.5 }}>
                    <Skeleton variant="text" sx={{ width: 300, height: 40 }} />
                    <Skeleton variant="text" sx={{ mx: 1, flexGrow: 1 }} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}

          {isFetched && fileSyncTasks.length === 0 && (
            <Box sx={{ py: 3 }}>
              <ZeroResultsReturned
                itemRequesting={'File Sync Tasks'}
                action={NewFileSyncTaskButton}
              />
            </Box>
          )}
          {/* If uploadRequests (not filtered) is 0 dont display. */}
          {isFetched && fileSyncTasks.length !== 0 && isFileSyncTasksNotFound && (
            <Box sx={{ py: 3 }}>
              <SearchNotFound searchQuery={filterName} />
            </Box>
          )}
          <Grid container rowSpacing={3}>
            {filteredFileSyncTasks.map((fileSyncTask) => (
              <FileSyncTaskCard key={fileSyncTask.uploadRequestId} fileSyncTask={fileSyncTask} />
            ))}
          </Grid>
        </Stack>
      </Container>
    </Page>
  );
}
